.slider3{
    position:relative;
    width:100%;
    margin: 0 auto;
    overflow: hidden;
}

.slider3-wrapper{
    display: flex;
    position:absolute;
    transition:transform 0.5s linear;
    width:100%;
    height:100%;
    .slide{
        flex: 1;
        min-width:60%;
        height:80%;
        background-color:grey;
        opacity:0.4;
        background-size:cover;
        background-position:center;
        background-repeat: no-repeat;
        transform: scale(0.8);
        z-index:97;
    }
}

$i: -1;
@for $i from -1 through 25{
    .slider3.active-slide-#{$i} .slide-#{$i}{
        opacity:1;
        transform:scale(1.4);
        transition: opacity 0.5s ease-in;
        transition: transform 0.5s ease-in;
        z-index: 98;
        box-shadow: 0px 5px 8px grey;
    }
    .slider3.prevactive-slide-#{$i} .slide-#{$i}{
        opacity:0.4;
        transform: scale(0.8);
        transition: opacity 0.5s ease-in;
        transition: transform 0.5s ease-in;
       
    }
    .slider3.nextactive-slide-#{$i} .slide-#{$i}{
        opacity:0.4;
        transform: scale(0.8);
        transition: opacity 0.5s ease-in;
        transition: transform 0.5s ease-in;
       
    }
}

.arrow:hover{
    transform:scale(1.2);
    transition:transform 0.3s linear;
}