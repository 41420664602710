body {
  margin: 0;
  min-width: 300px;
  font-family: Arial, Helvetica, tahoma, verdana, 宋体, SimSun, 华文细黑, STXihei, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:white;
  overflow-x: hidden;
  padding:0;
}



.body{
  position:relative;
  width:100%;
  background-color:rgba(255,255,255,1);
  padding:60px 0;

}
.menu-wrapper{
  position:fixed;
  width:100%;
  height:70px;
  top:0;
  left:0;
  z-index:999;
  padding:5px 30px;
}
.menu{
  width:50px;
  height:50px;
  position:relative;
  z-index:999;
  margin-left:auto;
}
.line{
  position:absolute;
  height:5px;
  width:100%;
  background-color:grey;
  border-radius: 10px;
  transition:all ease 0.3s;
}
.menu-open .line-1{
  top:19%;
}
.menu-open .line-2{
  top:49%;
}
.menu-open .line-3{
  top:79%;
}
.menu-close .line-1{
  transform: rotate(45deg);
  top:49%;
}
.menu-close .line-2{
  display:none;
}
.menu-close .line-3{
  transform: rotate(-45deg);
  top:49%;
}
.menu-body{
   position:fixed;
   top:0px;
   left:0px;
   height:100%;
   width:400px;
   z-index:997;
}
.menu-bg{
  position:fixed;
  top:0;
  left:0;
  height:100%;
  width:100%;
  background-color:rgba(145, 139, 139, 0.3)
}
.menu-list{
  position: fixed;
  list-style-type: none;
  width:100%;
  max-width:380px;
  height:100%;
  background-color:rgba(255,255,255,1);
  margin:0;
  padding:0;
  padding-top:50px;
}
.menu-list li{
  text-align: center;
  padding-top:40px;
  font-size:30px;
  font-weight:600;
}
.home-body{
  position: relative;
  margin:30px auto;
  width:95%;
  max-width:1500px;
  display:flex;
}
.body-left{
  position: relative;
  width:15%;
  text-align: center;
  z-index:99;
  
  
}
.body-left img{
  width:120px;
  z-index:999;
}
.body-right{
  position: relative;
  width:85%;
  height:auto;
  text-align: center;
}
.body-right h1{
  font-size:50px;
  text-shadow: 5px 5px 10px rgb(170, 169, 169);
}
.body-description{
  position: absolute;
  width:270px;
  height:auto;
  bottom:50px;
  left:-15%;
  padding:30px 30px 50px 30px;
  box-shadow: 0px 5px 15px rgb(170, 169, 169);
  background-color:rgba(238, 231, 231, 0.7)
}
.body-description h5{
  font-size:24px;
  text-align: center;
}
.body-description p{
  font-size:24px;
  text-align: left;
}
.body-right img{
  width:100%;
  height:auto;
}
.about-body{
  width:100vw;
  height:auto;
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.about-bg{
  width:100%;
  height:100%;
  background-color:rgba(238, 231, 231, 0.4);
  padding:120px 0px 120px 0px;
}
.about-description{
  font-size:22px;
  width:85%;
  max-width:900px;
  margin:auto;
  padding:30px 50px 30px 50px;
  box-shadow: 0px 5px 15px grey;
  border-radius: 10px;
  background-color:rgb(255, 255, 255)
}

.about-body img{
  padding:0px 20px 20px 20px;
  min-width:150px;
  width:40%;
}

.art-body{
  position:relative;
  width:100%;
  max-width:1400px;
  height:auto;
  overflow-y: hidden;
  margin:auto;
}
.art-body ul{
  position: relative;
  margin:0;
  padding:0;
  width:100%;
  list-style-type: none;
  top:0px;
  transition: top linear 1s;
}

.art-body ul li{
  display: inline-block;
  float:left;
  margin:0;
  width:33%;
  height:300px;
  padding: 10px;
}
.art-body ul li div{
  width:100%;
  height:100%;
  background-size:cover;
  background-position: center;
}
.load-btn{
  display:inline-block;
  font-size:40px;
}
.contact-body{
  margin:auto;
  padding:60px 0;
}
.contact-body .row{
  width:100%;
  max-width:1200px;
  margin:50px auto;
  font-size:20px;
  max-width:1100px;
}
.contact-body .row h5{
  font-size:25px;
}
.t-c{
  text-align:center;
}
.display-block{
  display:block;
}
.display-none{
  display:none;
}
a{
  color:black;
}
a:hover{
  text-decoration: none;
  color:grey;
}
@media only screen and (max-width: 1500px) {
  .body-description h5{ 
    font-size:22px;
  }
  .body-description p{ 
    font-size:21px;
  }

}
@media only screen and (max-width: 1100px) {
    .body{
      padding:30px 0;
    }
    .home-body{
      flex-wrap:wrap;
    }
    .body-left{
      width:100% !important;
    }
    .body-right{
      margin-top: 30px;
      width:100%;
    }
    .body-right h1{
      font-size:30px;
    }
    .body-description { 
      display:none;
    }
    .about-description{
      padding: 20px;
      font-size: 15px;
    }
    .about-description h2{
      font-size:20px;
    }
    .art-body ul li{
      width:50%;
    }
}
@media only screen and (max-width: 600px){
  .art-body ul li{
    width:100%;
  }
}